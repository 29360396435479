var site = site || {};
var generic = generic || {};

(function ($) {
  site.cartConfirm = site.cartConfirm || {};

  var template = $('script.inline-template[path="cart_confirm"]').html();
  var $cartConfirmWrapper = $(
    '#header .block.block-nodeblock[class*="cart"] .dd-content, #header-gnav-navigation .js-header-gnav-cart__content-wrapper'
  );
  var $cartConfirm = $('.gnav-cart-v1', $cartConfirmWrapper);
  // New for 2019 Redesign - panel slideout
  var $cartPanelOverlay = $cartConfirmWrapper.closest('.js-header-gnav-cart__overlay');
  var $userCartCount = site.userInfoCookie.getValue('item_count');

  site.cartConfirm.prepareCart = function (cartResult) {
    var $cartMiniProductOverlay = $(
      '.overlay-container  .cart-mpp-product-overlay-content .mpp-product, .overlay-container .module-spp-detail'
    );

    if ($cartMiniProductOverlay.length === 0) {
      generic.overlay.hide();
    }
    $cartConfirm.removeClass('empty');
    if (typeof cartResult.trans_data == 'undefined') {
      return;
    }
    var args = {
      trans: cartResult.trans_data,
      items: cartResult.trans_data.order.items,
      newItemID:
        typeof cartResult.ac_results !== 'undefined'
          ? cartResult.ac_results[0].result.CARTITEM['prod.PRODUCT_ID'] +
            cartResult.ac_results[0].result.CARTITEM['sku.SKU_ID']
          : undefined
    };

    site.userTransObj.setTransObject(cartResult.trans_data);
    site.cartConfirm.launch(args);
    $cartConfirmWrapper.addClass('active');
    var gnavSub = $cartConfirmWrapper.find('.gnav-item-content');

    if ($('html').hasClass('no-csstransitions')) {
      gnavSub.fadeIn('slow');
    }
    if ($cartPanelOverlay.length > 0) {
      $cartConfirmWrapper.addClass('active'); // CONNAVFO-1961 sometimes this is being missed
      $cartPanelOverlay.addClass('visible');
      $('body').addClass('active-gnav');
    }
    $('.last-item-added', $cartConfirmWrapper)
      .animate(
        {
          backgroundColor: $.Color({ alpha: 0 })
        },
        800
      )
      .removeClass('last-item-added');
  };

  site.cartConfirm.launch = function (args) {
    var trans = args.trans;
    var order = trans.order;
    var cartItems = !!order && !!order.samples ? args.items.concat(order.samples) : args.items;
    var totals = typeof trans.trans != 'undefined' ? trans.trans.totals : trans.totals;
    var templateData = { items: [] };
    var cartItem;
    var cartItemsCount = cartItems.length;
    var $cartConfirmWrapperTitle = $();

    if (site && site.track) {
      site.track.cartOverlay();
    }
    // Normalize key names in cartItem object. 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
    for (var i = 0; i < cartItemsCount; i++) {
      cartItem = cartItems[i];
      templateData.items[i] = {};
      for (var key in cartItem) {
        if (cartItem.hasOwnProperty(key)) {
          templateData.items[i][key.replace(/\./, '_')] = cartItem[key];
        }
      }
      templateData.items[i]['prod_THUMBNAIL_IMAGE'] = templateData.items[i]['sku_THUMBNAIL_IMAGE'];
      if ($cartPanelOverlay.length > 0) {
        // Larger image for 2019 redesign
        templateData.items[i]['prod_IMAGE_STICKY'] = templateData.items[i]['sku_THUMBNAIL_IMAGE'];
      } else {
        templateData.items[i]['prod_IMAGE_STICKY'] = templateData.items[i]['sku_IMAGE_STICKY'];
      }
      if (templateData.items[i]['itemType'] === 'SampleItem') {
        templateData.items[i]['isSampleItem'] = 1;
      } else {
        templateData.items[i]['isSampleItem'] = 0;
      }

      if (templateData.items[i]['itemType'] === 'KitItem') {
        templateData.items[i]['isKitItem'] = 1;
        templateData.items[i] = site.cartConfirm.buildKitItemData(templateData.items[i]);
      } else {
        templateData.items[i]['isKitItem'] = 0;
      }

      if (Drupal.settings.globals_variables.display_price_without_zero_decimal) {
        templateData.items[i][
          'formattedTaxedDiscountedPriceWithoutZeroDecimal'
        ] = removeZeroDecimals(templateData.items[i].formattedTaxedDiscounted);
        templateData.items[i][
          'formattedTaxedAppliedPriceWithoutZeroDecimal'
        ] = removeZeroDecimals(templateData.items[i].formattedTaxedAppliedPrice);
        templateData.items[i]['kit_formattedPriceWithoutZeroDecimal'] = removeZeroDecimals(
          templateData.items[i].kit_formattedPrice
        );
        templateData.items[i]['formattedPriceWithoutZeroDecimal'] = removeZeroDecimals(
          templateData.items[i].formattedPrice
        );
        templateData.items[i][
          'formattedDiscountedPriceWithoutZeroDecimal'
        ] = removeZeroDecimals(templateData.items[i].formattedDiscountedPrice);
        templateData.items[i]['formattedAppliedPriceWithoutZeroDecimal'] = removeZeroDecimals(
          templateData.items[i].formattedAppliedPrice
        );
        templateData.items[i][
          'formattedTaxedUnitPriceWithoutZeroDecimal'
        ] = removeZeroDecimals(templateData.items[i].formattedTaxedUnitPrice);
        templateData.items[i]['sku_formattedPriceWithoutZeroDecimal'] = removeZeroDecimals(
          templateData.items[i].sku_formattedPrice
        );
        templateData.items[i]['formattedUnitPriceWithoutZeroDecimal'] = removeZeroDecimals(
          templateData.items[i].formattedUnitPrice
        );
        templateData.items[i][
          'formattedDiscountedUnitPriceWithoutZeroDecimal'
        ] = removeZeroDecimals(templateData.items[i].formattedDiscountedUnitPrice);
      }
    }

    templateData.formattedSubtotal = trans.formattedSubtotal;
    templateData.items_count = trans.items_count;
    $cartConfirm.html(Mustache.render(template, templateData));
    $('a.close, .buttons .left-copy.continue a', $cartConfirm).on('click', function (e) {
      e.preventDefault();

      // updated for 2019 redesign
      if (!$('body').hasClass('active-gnav')) {
        $cartConfirmWrapper.removeClass('active');
      }

      if ($('html').hasClass('no-csstransitions')) {
        var gnavSub = $cartConfirmWrapper.find('.gnav-item-content');

        gnavSub.fadeOut('slow');
      }
    });

    if (templateData.items_count !== 1) {
      $('.items', $cartConfirm).addClass('plural');
    }

    // If an item was just added, move it to the top of the products and give it a special class
    if (typeof args.newItemID != 'undefined') {
      // Avoid Duplicate Gift Card SKU's #182184
      $('.prod-' + args.newItemID, $cartConfirm)
        .first()
        .addClass('last-item-added')
        .prependTo('.cart-overlay-products');
      // commenting out per bug#187151
      /*
       if (typeof sku != 'undefined') {
       if (sku.REFILLABLE === 1) {$('.cart-confirm-heading', $cartConfirm).removeClass('hidden');}
       }
       */
    }

    // Dynamically add the last item added title to the cart confirm wrapper heading
    $cartConfirmWrapperTitle = $cartConfirmWrapper.find('.cart-confirm-wrapper h2 span.item-added');

    // show link based offer message to user if there is an link based offer active
    if ($.cookie('offer_info') !== '' && !!$.cookie('offer_info')) {
      var offer_code = $.cookie('offer_info').split(':')[1];

      generic.jsonrpc.fetch({
        method: 'offer.linkedOfferState',
        params: [offer_code],
        onSuccess: function (r) {
          var offer = r.getValue();
          var isActive = !!offer.linked_offer && offer.linked_offer.length !== 0;

          if (isActive) {
            var linkOfferMessage =
              offer.linked_offer.offer_message.length !== 0 ? offer.linked_offer.offer_message : '';

            $cartConfirmWrapperTitle.html(linkOfferMessage);
          }
        },
        onFailure: function () {}
      });
    } else {
      cartMessage();
    }

    function cartMessage() {
      // show free shipping message to user if there is an offer active
      if (trans.offerOverlayMessage !== '') {
        $cartConfirmWrapperTitle.html(trans.offerOverlayMessage);
      } else {
        // show the normal add to bag success message
        if (typeof args.newItemID != 'undefined') {
          $cartConfirmWrapperTitle.text($('[data-localtrans="cart_confirm_success_msg"]').html());
        } else {
          $cartConfirmWrapperTitle.text(
            $('span[data-localtrans="cart_confirm_you_have"]').html() +
              ' ' +
              templateData.items_count +
              ' ' +
              (templateData.items_count === 1
                ? $('[data-localtrans="cart_confirm_item"]').html()
                : $('[data-localtrans="cart_confirm_items"]').html()) +
              ' ' +
              $('[data-localtrans="cart_confirm_in_bag"]').html()
          );
        }
      }
    }

    function removeZeroDecimals(strFormattedPrice) {
      var numPriceWithoutCurrency;
      var arrayPriceWithoutCurrency;
      var strCurrency;
      var strNewPrice;
      var arrayPrice;

      if (strFormattedPrice === null || strFormattedPrice === undefined) {
        return strFormattedPrice;
      }
      arrayPriceWithoutCurrency = strFormattedPrice.split(/\s+/);
      if (arrayPriceWithoutCurrency.length) {
        arrayPriceWithoutCurrency.pop();
      }
      numPriceWithoutCurrency = parseFloat(
        arrayPriceWithoutCurrency.toString().replace(',', '')
      ).toFixed(2);
      strCurrency = getCurrency(strFormattedPrice);

      if (numPriceWithoutCurrency % 1 === 0) {
        arrayPrice = strFormattedPrice.split('.');
        if (arrayPrice.length) {
          arrayPrice.pop();
        }
        strNewPrice = arrayPrice.toString() + ' ' + strCurrency;
      } else {
        strNewPrice = strFormattedPrice;
      }

      return strNewPrice;
    }

    function getCurrency(price) {
      var strCurrency = '';
      if (price) {
        strCurrency = price.slice(price.length - 1);
      }
      return strCurrency;
    }

    // Sort out the shade info areas
    $('.shade_thumb[data-hex!=""]', $cartConfirmWrapper).each(function () {
      var hex = $(this).attr('data-hex');

      $(this).parent().removeClass('hidden');
      if (hex.split(',').length > 1) {
        $(this).attr('style', '');
        for (var i = 0; i < hex.split(',').length; i++) {
          $(this).append(
            '<div class="shade_thumb_' +
              hex.split(',').length +
              '" style="background-color: #' +
              hex.split(',')[i] +
              '"></div>'
          );
        }
      }
    });

    if (!Drupal.settings.globals_variables.disable_float_price) {
      $('.shipping span', $cartConfirm).html(parseFloat(totals.SHIPPING_W_TAX).toFixed(2));
      $('.total span', $cartConfirm).html(parseFloat(totals.TOTAL_COST_W_TAX).toFixed(2));
      $('.subtotal_output span', $cartConfirm).html(
        parseFloat(totals.PRODUCT_SUM_W_TAX).toFixed(2)
      );
    } else {
      $('.shipping span', $cartConfirm).html(parseInt(totals.SHIPPING_W_TAX) + ' ');
      $('.total span', $cartConfirm).html(parseInt(totals.TOTAL_COST_W_TAX) + ' ');
      $('.subtotal_output span', $cartConfirm).html(parseInt(totals.PRODUCT_SUM_W_TAX) + ' ');
    }

    if (Drupal.settings.globals_variables.display_price_without_zero_decimal) {
      if (Drupal.settings.globals_variables.language_code) {
          var subtotal = new Intl.NumberFormat(Drupal.settings.globals_variables.language_code,
            { style: 'currency', currency: Drupal.settings.globals_variables.currency_code }).format(parseFloat(totals.PRODUCT_SUM_W_TAX));
          $('.subtotal_output span', $cartConfirm).html(removeZeroDecimals(subtotal.toString().replace(',', '.')));
        }
    }

    var is_anonymous_user = site.userInfoCookie.getValue('signed_in') !== '1';

    if (!is_anonymous_user && Drupal.settings.cart_loyalty_prices_enabled) {
      $(document).trigger('productQV:rendered:LoyaltyPrices', $('.cart-confirm-wrapper'));
    }

    // close confirm overlay on scroll (bug#180737)
    $(window).on(
      'scroll',
      _.throttle(function () {
        $('.cart-confirm-wrapper a.close', $cartConfirm).trigger('click');
      }, 100)
    );
  };

  /*
   * Build derived fields for collection type items
   */
  site.cartConfirm.buildKitItemData = function (item) {
    var kitName = [];
    var kitImageProductCode = [];
    var baseImg = '';
    var baseProductCode = '';
    var thumbImage;

    item['isKitItem'] = 1;
    if (item['ITEM_QUANTITY'] === null) {
      item['ITEM_QUANTITY'] = 1;
    }
    item['kit_formattedPrice'] = item['basePrice'].toFixed(2);
    if (item['items'].length) {
      thumbImage = item['items'][0]['sku.THUMBNAIL_IMAGE'];
      if ($cartPanelOverlay.length > 0 && typeof thumbImage !== 'undefined') {
        baseImg = thumbImage;
      } else {
        baseImg = item['items'][0]['sku.IMAGE_STICKY'];
      }
      baseProductCode = item['items'][0]['sku.PRODUCT_CODE'];

      item['items'].map(function (item) {
        // since only one of the components will have the .Net field set
        // we use it as flag to determine the order we will build the collection image + name
        if (item['prod.GIFTSET_COMPONENTS']) {
          kitName.push(item['prod.PROD_RGN_NAME'].trim());
          kitImageProductCode.push(item['sku.PRODUCT_CODE']);
        } else {
          kitName.unshift(item['prod.PROD_RGN_NAME'].trim());
          kitImageProductCode.unshift(item['sku.PRODUCT_CODE']);
        }
      });

      item['kitName'] = kitName.join(' + ');
      item['kitStickyImage'] = baseImg.replace(baseProductCode, kitImageProductCode.join('_'));
      if (item.isVirtualBundling && item.ukitCustomDetails) {
        item['kitName'] = item.ukitCustomDetails.title;
        item['kitStickyImage'] = item.ukitCustomDetails.image;
      }
    }

    return item;
  };

  site.cartConfirm.getTransData = function (callback) {
    generic.jsonrpc.fetch({
      method: 'trans.get',
      params: [
        {
          trans_fields: ['TRANS_ID', 'totals'],
          payment_fields: [],
          order_fields: ['items']
        }
      ],
      onSuccess: function (result) {
        var cartResult = {};

        cartResult.trans_data = result.getValue();
        $(document).trigger('trans_data.loaded', cartResult);
        if (typeof callback === 'function') {
          callback(cartResult);
        }
        site.loading.hide();
      }
    });
  };

  site.cartConfirm.clearUrl = function () {
    window.location = window.location.href.replace(window.location.search, '');
  };

  Drupal.behaviors.cartConfirm = {
    attach: function (context) {
      $(document).on('addToCart.success', function (e, cartResult) {
        site.cartConfirm.prepareCart(cartResult);
      });
      $(document).on(
        'addToCartKitItem.success editCartKitItem.success updateSampleItems.success',
        function (e, args) {
          site.cartConfirm.getTransData(args.callback);
        }
      );

      $(document).on('cart.loaded', function (event, cartResult) {
        if (cartResult === undefined || !cartResult.order.items.length) {
          $cartConfirm.addClass('empty');

          return;
        } else {
          $cartConfirm.removeClass('empty');
        }
        var args = {
          trans: cartResult,
          items: cartResult.order.items
        };

        site.cartConfirm.launch(args);
      });

      // NOTE: Uncomment to style the add to cart popup window
      // $cartConfirmWrapper.css({'display':'block'});

      // only load trans object if they mouseover cart icon
      $('.block-template-gnav-cart-v1, #sticky-bar .shopping-bag').hover(function (e) {
        if ($('.gnav-cart-v1 .cart-confirm-wrapper', context).length === 0) {
          site.userTransObj.getTransObject();
        }
      });

      // 2019 Redesign - panel on click not hover, only when we have a count
      $('#header-gnav-navigation .js-header-gnav-cart__trigger')
        .once()
        .on('click', function (e) {
          if ($userCartCount.length && $userCartCount > 0) {
            e.preventDefault();
            $cartPanelOverlay.addClass('visible');
            $('body').addClass('active-gnav');
            $cartConfirmWrapper.addClass('active');
            if ($('.gnav-cart-v1 .cart-confirm-wrapper', context).length === 0) {
              site.userTransObj.getTransObject();
            }
          }
        });
    }
  };

  var getObjects = function (obj, key, val) {
    var objects = [];

    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) {
        continue;
      }
      if (typeof obj[i] === 'object') {
        objects = objects.concat(getObjects(obj[i], key, val));
      } else if (i === key && obj[key] === val) {
        objects.push(obj);
      }
    }

    return objects;
  };
})(jQuery);
